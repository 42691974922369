import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Login } from '../interfaces/Login'
import * as moment from "moment";
import { LoginModel } from '../interfaces/LoginModel';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
    _baseURL: string = 'user';
    userData = new BehaviorSubject<Login>(new Login());
    invalidLogin: boolean;
    userRole: string;
    quizStatus: string='false';    
  constructor(private http: HttpClient, private router: Router) { }


    login(user: LoginModel) {
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }; 
        return this.http.post<any>(this._baseURL + '/login', user)
            .pipe(map(response => {
              localStorage.setItem('authToken', response.token);
              localStorage.setItem('quizStatus', this.quizStatus);
                this.setUserDetails(); 
                return response;
            }));              
           
    }


    // Sign-in
    sslogin(user: LoginModel) {
        return this.http.post<any>(this._baseURL + '/login/', user)
            .subscribe((res: any) => {
                localStorage.setItem('access_token', res.token)
                //  this.getUserProfile(res._id).subscribe((res) => {//
                //      this.currentUser = res;
                this.router.navigate(['/home']);
          //  })
    })
    }




    setUserDetails() {
        if (localStorage.getItem('authToken')) {
            const userDetails = new Login();
            const decodeUserDetails = JSON.parse(window.atob(localStorage.getItem('authToken').split('.')[1]));

           // userDetails.userName = decodeUserDetails.sub;
            userDetails.userName = decodeUserDetails.userName;
            userDetails.fullName = decodeUserDetails.fullName;
            userDetails.isLoggedIn = true;
            userDetails.role = decodeUserDetails.role;
            userDetails.lastLogin = decodeUserDetails.lastLogin;
            this.userRole = userDetails.role;

            this.userData.next(userDetails);
        }
    }

  logout() {
    
        localStorage.removeItem('authToken');
        localStorage.removeItem('quizStatus');
        this.router.navigate(['/login']);
        this.userData.next(new Login());
    }

    isAdmin() {
        const decodeUserDetails = JSON.parse(window.atob(localStorage.getItem('authToken').split('.')[1]));
        this.userRole = decodeUserDetails.role;      

       
        return this.userRole;
    }


  public isAuthenticated(): boolean {
    const token = localStorage.getItem('authToken');
    // Check whether the token is expired and return
    // true or false
 //   return !this.jwtHelper.isTokenExpired(token);

    return true;
  }

          
}
