import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {
  quizSubmitted: boolean = false;
  constructor() { }

  ngOnInit() {

    let quizStatus = localStorage.getItem('quizStatus');

    this.quizSubmitted = Boolean(JSON.parse(quizStatus));
          

    localStorage.setItem('quizStatus', 'false');
  }

  btnClose() {
    localStorage.setItem('quizStatus', 'false');
    window.close();
  }
}
