import { Component } from '@angular/core';
import { UserRole } from '../../interfaces/UserRole';
import { Login } from '../../interfaces/Login';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
    isExpanded = false;

    //////////////////////////////////////////////////
    userDataSubscription: any;
    userData = new Login();
    userRole = UserRole;

    constructor(private authService: AuthService,private uservice: UserService) {
        this.userDataSubscription = this.authService.userData.asObservable().subscribe(data => {
          this.userData = data;
          let sname = this.userData.fullName;
          localStorage.setItem('sname', sname);
        });
    }    
    //////////////////////////////////////////////////////////////////////////////////////////////
  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
    }
    ////////////////////////////////////////////////
     async logout() {
  //  await this.uservice.logout().subscribe(data => {
 //     alert(data);      
 //   })
       await this.uservice.logout().toPromise();
       localStorage.removeItem('sname');
       this.authService.logout();
      
    }
    ///////////////////////////////////////////////
}
