import { UserService } from './services/user.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { HomeComponent } from './components/home/home.component';

import { AllUsersComponent } from './components/all-users/all-users.component';

import { LoginComponent } from './components/login/login.component';

import { AuthGuard } from './guards/auth.guard';
import { AdminGuard } from './guards/admin.guard';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { ErrorInterceptorService } from './services/error-interceptor.service';
import { AuthService } from './services/auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    MatDatepickerModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatNativeDateModule,
    MatToolbarModule,
    
} from '@angular/material';


import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatRadioModule } from '@angular/material/radio';
import {MatBadgeModule} from '@angular/material/badge';


/////////
// Import your library

/* Angular material */

import {
    MatTabsModule, MatListModule, MatSelectModule, MatInputModule, MatButtonModule, MatDialogModule
} from '@angular/material';

import { AdminDialogComponent } from './components/admin-dialog/admin-dialog.component';

import { TeacherGuard } from './guards/teacher.guard';


//Idle Session Management Package
import { UserIdleModule, UserIdleService } from 'angular-user-idle';

import { ViewEnrolls } from './interfaces/ViewEnrolls';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { StripeModule } from "stripe-angular"
import { ManagerGuard } from './guards/manager.guard';
import { AdminSideComponent } from './components/admin-side/admin-side.component';
import { UserSideComponent } from './components/user-side/user-side.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ManagerSideComponent } from './components/manager-side/manager-side.component';
import { ProgressGuard } from './guards/progress.guard';
import { YesNoComponent } from './components/yes-no/yes-no.component';



// Idle Session management Package

/////////


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,    
    
    AllUsersComponent,  
    LoginComponent, 
   
    AdminDialogComponent, SideNavComponent, AdminSideComponent,AdminSideComponent, UserSideComponent, NotFoundComponent, ManagerSideComponent, YesNoComponent
       
    
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
      StripeModule.forRoot(""),
      HttpClientModule,
      FormsModule,
      ReactiveFormsModule,
      BrowserAnimationsModule,

      MatTabsModule,
      MatListModule,
      MatSelectModule,      
      MatInputModule,
      MatButtonModule,
      MatDialogModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatSidenavModule,
      MatSliderModule,
    MatRadioModule,

   
///////////////////////////////
///////////////////////////////
    MatMenuModule,
    MatIconModule,
    MatToolbarModule,
    MatDividerModule,
    MatBadgeModule,
  /////////////////////
    /////////////////////////

    ////////////////////
    MatToolbarModule,
    ///////////////////


    // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes) 
    // and `ping` is 120 (2 minutes).
    UserIdleModule.forRoot({ idle: 60, timeout: 300, ping: 120 }),
      
    RouterModule.forRoot([

      { path: '', component: LoginComponent, pathMatch: 'full' },

      { path: 'addusers', loadChildren: () => import('./add-users/add-users.module').then(m => m.AddUsersModule) },
   
        { path: 'all-users', component: AllUsersComponent, canActivate: [AdminGuard]  },
     
      { path: 'login', component: LoginComponent, data: { animation: 'isRight' } },                   
           
      { path: 'manager', loadChildren: () => import('./manager/manager.module').then(m => m.ManagerModule), canActivate: [ManagerGuard] },      

    //    { path: 'manager', loadChildren: () => import('./manager/manager.module').then(m => m.ManagerModule), canActivate: [AuthGuard]  },

      { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule),canActivate: [AuthGuard] },

      { path: 'teacher', loadChildren: () => import('./teacher/teacher.module').then(m => m.TeacherModule), canActivate: [TeacherGuard] },

      { path: 'adminhome', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), data: { animation: 'isLeft' }, canActivate: [AdminGuard] },
     
      { path: 'forgotpass', loadChildren: () => import('./forgot-pass/forgot-pass.module').then(m => m.ForgotPassModule) },
      { path: 'payments/:id1/:id2/:id3', loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule), canActivate: [AuthGuard] },
      { path: 'progress/:id1/:id2/:id3', loadChildren: () => import('./progress/progress.module').then(m => m.ProgressModule), canActivate: [ProgressGuard] },
      // { path: '**', redirectTo: '/login' },   
      { path: '404', component: NotFoundComponent },
      { path: '**', component: NotFoundComponent }

     
            
        
    ]),
      
    BrowserAnimationsModule
  ],
  providers: [UserService, AuthService, { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },],
    
    bootstrap: [AppComponent],
    entryComponents: [AdminDialogComponent,YesNoComponent]  //for MatDialog AdminDialogComponent
})
export class AppModule {

}
