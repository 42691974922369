import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Login } from '../../interfaces/Login';
import { UserRole } from '../../interfaces/UserRole';
import { AuthService } from '../../services/auth.service';    

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    loading = false;
    loginForm: FormGroup;
    submitted = false;
    returnUrl: string;
    error = "";
  userRole: string;
  showLogin: boolean = true;
  invalidLogin: boolean = false;

  //////////////////////////////////////////////////
  userDataSubscription: any;
  userData = new Login();
  loguserRole = UserRole;
  alreadyLogged: boolean = false;


    constructor(private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService) {

      this.userDataSubscription = this.authService.userData.asObservable().subscribe(data => {
        this.userData = data;
      });

    }

  ngOnInit() {

    this.checkIsLogged();
        this.loginForm = this.formBuilder.group({
            userName: ['', Validators.required],
            password: ['', Validators.required]
        });
    }


  checkIsLogged() {

    if (this.userData.isLoggedIn) {
      this.showLogin = false;
      //   if (this.userData.role == this.loguserRole.User) {
      //     this.router.navigate(['/user-home']);
      //   }

      if (this.userData.role == this.loguserRole.User) {
        localStorage.setItem('uids', this.userData.fullName);
        this.router.navigate(['/users']);
      }

      if (this.userData.role == this.loguserRole.Teacher) {
        this.router.navigate(['/teacher']);
      }

      if (this.userData.role == this.loguserRole.Admin) {
        this.router.navigate(['/adminhome']);
      }

      if (this.userData.role == this.loguserRole.Manager) {
        this.router.navigate(['/manager']);
      }
    } //isLogged in check ends here
    else {
      this.showLogin = true;
    }

  }  //checkIsLogged ends here
   // get loginFormControl() { return this.loginForm.controls; }
        


    oonSubmit() {
        this.authService.login(this.loginForm.value)
    }


  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.invalidLogin = false;
    this.alreadyLogged = false;
    this.loading = true;
    const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/';
    this.authService.login(this.loginForm.value)
      .pipe(first())
      .subscribe(
        () => {

          
          this.checkIsLogged();
        },
        error => {
          this.loading = false;
          this.loginForm.reset();

          //User Id Or Password invalid
          if (error == 404) {
            this.invalidLogin = true;
            
            this.loginForm.setErrors({
              invalidLogin: true
            });
          }

          //User Id Or Password invalid
          if (error == 400) {

            this.alreadyLogged = true;
          }

          //Bad Request
          //    else if (error == 400) {

          //      this.loginForm.setErrors({
          //      invalidRequest: true
          //    });
          //  }

          else {
            this.loginForm.setErrors({
              invalidRequest: true
            });
          }

        });
   

  }    
    
}
