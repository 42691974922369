import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
    selector: 'app-admin-dialog',
    templateUrl: './admin-dialog.component.html',
    styleUrls: ['./admin-dialog.component.css']
})
export class AdminDialogComponent implements OnInit {

    form: FormGroup;
    description: string;
    title: string;

    constructor(public dialogRef: MatDialogRef<AdminDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data
        
    ) {
        
      this.description = data.description;
      this.title = data.title;

        
    }

    ngOnInit() {
       
    }

    save() {
        
    }

    close() {
        this.dialogRef.close();
    }

}
