import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Courses } from '../interfaces/Courses';
import { NgModel, FormGroup } from '@angular/forms';
import { Enroll } from '../interfaces/Enroll';
import { Observable } from 'rxjs';
import { Program } from '../interfaces/Program';
import { Category } from '../interfaces/Category';
import { ProgramView } from '../interfaces/ProgramView';
import { ResetPassword } from '../interfaces/ResetPassword';
import { InstructorView } from '../interfaces/InstructorView';
import { AssignB } from '../interfaces/AssignB';
import { DatePipe } from '@angular/common';
import { CourseView } from '../interfaces/CourseView';
import { EmailModel } from '../interfaces/EmailModel';
import { ResetToken } from '../interfaces/ResetToken';
import { verifyToken } from '../interfaces/verifyToken';
import { GetEnrollment } from '../interfaces/GetEnrollment';
//import { courseVideo } from '../interfaces/courseVideo';
import { Instructor } from '../interfaces/Instructor';
import { GetBatches } from '../interfaces/GetBatches';
import { TeacherUpload } from '../interfaces/TeacherUpload';
import { AssignmentById } from '../interfaces/AssignmentById';
import { saveAs as importedSaveAs } from "file-saver";
import { MAnouncement } from '../interfaces/MAnouncement';
import { StudentAssignments } from '../interfaces/StudentAssignments';
import { AddQuiz } from '../interfaces/AddQuiz';
import { AddQuestion } from '../interfaces/AddQuestion';
import { QuizView } from '../interfaces/QuizView';
import { GetStudentQuizes } from '../interfaces/GetStudentQuizes';
import { GetQQuestions } from '../interfaces/GetQQuestions';
import { QuizAnswers } from '../interfaces/QuizAnswers';
import { UpdateQuiz } from '../interfaces/UpdateQuiz';
import { TimeSpent } from '../interfaces/TimeSpent';
import { StudentAnnouncement } from '../interfaces/StudentAnnouncement';
import { ViewEnrolls } from '../interfaces/ViewEnrolls';
import { ResetSPassword } from '../interfaces/ResetSPassword';
import { UserView } from '../interfaces/UserView';
import { FetchAssignments } from '../interfaces/FetchAssignments';
import { MarksAssigned } from '../interfaces/MarksAssigned';
import { BADRESP } from 'dns';
import { FetchAssignData } from '../interfaces/FetchAssignData';
import { GetSQuestions } from '../interfaces/GetSQuestions';
import { replyQuestion } from '../interfaces/ReplyQuestion';
import { GetAlertClick } from '../interfaces/GetAlertClick';
import { QuizDetail } from '../users/uinterface/QuizDetail';
import { ViewQuizProgress } from '../interfaces/ViewQuizProgress';
import { ViewQResult } from '../interfaces/ViewQResult';
import { ViewAProgress } from '../interfaces/ViewAProgress';
import { ViewAResult } from '../interfaces/ViewAResult';
import { GetData } from '../interfaces/GetData';
import { AiChat } from '../users/uinterface/AiChat';
import { CourseCount } from '../interfaces/CourseCount';
import { setVideos } from '../interfaces/setVideos';
import Player from '@vimeo/player';
import { GetCCertificate } from '../users/uinterface/GetCCertificate';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  _baseURL: string = 'user';
  _localURL: string = 'http://127.0.0.1:8000/'
  result: string;
  datePipe: DatePipe;
  sDate: any;
  fileData: Blob;
  infoUser: any;
  /////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////
 
  //////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////






  constructor(private http: HttpClient) { }

  getAllUsers() {
    return this.http.get<User[]>(this._baseURL + '/ListUsers');


  }

  getAllCourses() {
    return this.http.get<Courses[]>(this._baseURL + '/GetCourses');

  }

  getEnrollCourses() {
    return this.http.get<GetEnrollment[]>(this._baseURL + '/GetEnrollCourses');

  }


  addUser(user: User) {

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<string>(this._baseURL + "/Register/", user, httpOptions);

  }




  emailCheckUnique(vemail) {
    return this
      .http.get(this._baseURL + "/verifyEmail/", vemail);
  }

  getUserData() {
    return this.http.get(this._baseURL + "/GetUserData").pipe(map(result => result));
  }

  getAdminData() {
    return this.http.get(this._baseURL + "/GetAdminData").pipe(map(result => result));
  }


  getUserCourse() {
    return this.http.get(this._baseURL + "/GetUserCourse").pipe(map(result => result));
  }

  getInstructor() {
    return this.http.get<InstructorView[]>(this._baseURL + "/GetInstructor");
  }


  getCategory() {
    return this.http.get<Category[]>(this._baseURL + '/GetCategory');
  }

  getBatches() {
    return this.http.get<GetBatches[]>(this._baseURL + '/GetBatches');
  }

  addCourse(enroll: Enroll): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<Enroll>(this._baseURL + '/AddRole', enroll, httpOptions);

  }

  addProgram(formData, fileUpload: File) {

    const uploadData = new FormData();


    uploadData.append('programName', formData.programName);
    uploadData.append('category', formData.category);
    uploadData.append('programDuration', formData.programDuration);
    uploadData.append('programFees', formData.programFees);
    uploadData.append('totalModules', formData.totalModules);

    uploadData.append('fileName', fileUpload.name);
    uploadData.append('assignFile', fileUpload, fileUpload.name);



    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<string>(this._baseURL + "/AddProgram/", uploadData);

  }

  getCourseById(id: number): Observable<any> {
    const urlpath = this._baseURL + '/GetCourseById';
    const url = '${urlpath}/${id}';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    // alert(id);
    return this.http.get<CourseView[]>(this._baseURL + "/GetCourseById/" + id, httpOptions);
  }

  getQCourseById(id: number): Observable<any> {
    const urlpath = this._baseURL + '/GetCourseById';
    const url = '${urlpath}/${id}';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    // alert(id);
    return this.http.get<CourseView[]>(this._baseURL + "/GetQCourseById/" + id, httpOptions);
  }

  getAssignmentById(id: number): Observable<any> {

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    // alert(id);
    return this.http.get<AssignmentById[]>(this._baseURL + "/GetAssignmentById/" + id, httpOptions);
  }



  //this routine will fetch the assignments submitted by students
  FetchAssignments(id) {

    return this.http.get<FetchAssignments[]>(this._baseURL + "/FetchAssignments/" + id);
  }



  getCourseByUId(id: number): Observable<any> {
    const urlpath = this._baseURL + '/GetCourseById';
    const url = '${urlpath}/${id}';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    // alert(id);
    return this.http.get<ProgramView[]>(this._baseURL + "/GetCourseByUId/" + id, httpOptions);

  }


  enrollCourse(id: number): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<Enroll>(this._baseURL + '/enrollCourse', id, httpOptions);

  }

  resetPassword(reset: ResetPassword) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<string>(this._baseURL + '/resetPassword', reset, httpOptions);

  }


  addInstructor(instructor: Instructor) {

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<string>(this._baseURL + "/AddTeacher/", instructor, httpOptions);

  }

  addBatch(assignbatch: AssignB) {


    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<string>(this._baseURL + "/AssignBatch/", assignbatch, httpOptions);

  }



  ////////////////////////////////////////////////////
  ///////////////////////////////////////////////////

  //This routine get programs by categoury wise Starts

  getProgramById(id: number): Observable<any> {
    const urlpath = this._baseURL + '/GetCourseById';
    const url = '${urlpath}/${id}';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    // alert(id);
    return this.http.get<ProgramView[]>(this._baseURL + "/GetProgramById/" + id);

  }


  //This routine get programs by categoury wise Ends
  /////////////////////////////////////////////////////
  /////////////////////////////////////////////////////


  getCurrentProgram(): Observable<any> {
    const urlpath = this._baseURL + '/GetCourseById';
    const url = '${urlpath}/${id}';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.get<ProgramView[]>(this._baseURL + "/GetCurrentProgram/");

  }


  getRecommendProgram(): Observable<any> {
    const urlpath = this._baseURL + '/GetCourseById';
    const url = '${urlpath}/${id}';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.get<ProgramView[]>(this._baseURL + "/GetRecommendProgram/");

  }



  forgotPass(model: EmailModel): Observable<any> {

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<string>(this._baseURL + "/ForgotPassword/", model, httpOptions);
  }

  verifyToken(vtoken: verifyToken) {

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<string>(this._baseURL + "/VerifyToken/", vtoken, httpOptions);
  }

  resetPass(reset: ResetPassword) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<string>(this._baseURL + '/resetPassword', reset, httpOptions);

  }

  rePassword(reset: ResetToken) {


    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this._baseURL + '/rePassword', reset, httpOptions);

  }

  //Teacher Assignment Uploading
  addTeacherUpload(formdata, fileUpload: File) {

    const uploadData = new FormData();
    const uploadFile = new FormData();

    uploadData.append('batchId', formdata.batchId);
    //   uploadData.append('Description', formdata.Description);
    uploadData.append('dueDate', formdata.dueDate);
    uploadData.append('AssignNo', formdata.AssignNo);
    uploadData.append('assignFile', fileUpload, fileUpload.name);
    uploadData.append('pubStatus', formdata.pubStatus);
    uploadData.append('fileName', fileUpload.name);
    uploadData.append('maxMarks', formdata.maxMarks);

    uploadFile.append('assignFile', fileUpload, fileUpload.name);

    formdata.assignFile = fileUpload;


    ////////////////////////
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    return this.http.post<string>(this._baseURL + "/UploadTeacher/", uploadData);

    ////////////////////////////////////////////
  }


  ///////////////////////////////
  /// update Teacher uploaded Assignments
  /////////////////////////
  updateTeacherUpload(formdata, fileUpload: File) {

    const uploadData = new FormData();
    const uploadFile = new FormData();


    if (!fileUpload) {

      uploadData.append('dueDate', formdata.dueDate);
      uploadData.append('AssignId', formdata.AssignNo);
      uploadData.append('maxMarks', formdata.maxMarks);
      uploadData.append('pubStatus', formdata.pubStatus);

      return this.http.post<string>(this._baseURL + "/UpdateTeacherAssign/", uploadData);
    }
    else {


      uploadData.append('batchId', formdata.batchId);

      uploadData.append('dueDate', formdata.dueDate);
      uploadData.append('AssignNo', formdata.AssignNo);
      uploadData.append('maxMarks', formdata.maxMarks);
      uploadData.append('pubStatus', formdata.pubStatus);

      uploadData.append('assignFile', fileUpload, fileUpload.name);
      uploadData.append('fileName', fileUpload.name);

      //  uploadFile.append('assignFile', fileUpload, fileUpload.name);

      //  formdata.assignFile = fileUpload;

      ////////////////////////

      return this.http.post<string>(this._baseURL + "/UpdateTeacherFile/", uploadData);

    }


  } //UpdateTeacherUpload ends here

  //////////////////////////////
  ////downLoad Files
  //////////////////////////////

  downloadFiles(id: any) {

    const headers = new Headers();

    // return this.http.get(this._baseURL + '/GetBlobDownload/' + id, { responseType: 'blob' })
    //  return this.http.get(this._baseURL + '/GetBlobDownload/' + id, { observe: 'response' })
    return this.http.get(this._baseURL + '/GetSSHDownload/' + id, { responseType: 'blob' })

    //  return this.http.get(this._baseURL + '/FilesDownload/', { responseType: 'blob' })
  }

  private saveToFileSystem(response) {
    const contentDispositionHeader: string = response.headers.get('Content-Disposition');
    const parts: string[] = contentDispositionHeader.split(';');
    const filename = parts[1].split('=')[1];
    const blob = new Blob([response._body], { type: response.type });
    importedSaveAs(blob, filename);;
  }

  UnpubAssignment(id: any) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.get<AssignmentById[]>(this._baseURL + "/GetUnPubAssignment/" + id, httpOptions);
  }

  pubAssignmentNow(id: any) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.put<string>(this._baseURL + "/pubAssignmentNow/" + id, httpOptions);
  }


  AnounceSubmit(formdata, AnounceId) {

    const uploadData = new FormData();

    let Description: string;
    Description = formdata.Description;

    uploadData.append('batchIdA', AnounceId);
    uploadData.append('Description', formdata.Description);

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this._baseURL + "/addAnounce/", uploadData);

  }

  GetStudentAssignments(id) {

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get<StudentAssignments[]>(this._baseURL + "/GetStudentAssignments/" + id, httpOptions);

  }



  //Student Assignment Uploading
  addStudenUpload(formdata, fileUpload: File, id) {

    const uploadData = new FormData();


    //   uploadData.append('Description', formdata.Description);

    uploadData.append('AssignNo', id);
    uploadData.append('fileName', fileUpload.name);
    uploadData.append('assignFile', fileUpload, fileUpload.name);



    ////////////////////////

    return this.http.post<string>(this._baseURL + "/StudentUploads/", uploadData);

    ////////////////////////////////////////////
  }


  downloadSFiles(id: any) {


    return this.http.get(this._baseURL + '/GetSFileDownload/' + id, { responseType: 'blob' })

  }

  FetchStudentFile(id: any) {

    return this.http.get(this._baseURL + '/FetchStudentFile/' + id, { responseType: 'blob' })
  }



  addQuiz(formdata: AddQuiz) {
    // const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.post<string>(this._baseURL + "/addQuizes/", formdata);
  } //addQuiz Ends here


  //Add Quiz Question Section
  addQuestion(formdata: AddQuestion) {

    return this.http.post<string>(this._baseURL + "/addQuestion/", formdata);
  } //addQuiz Question Ends here

  getQuizById(id: number) {

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.get<QuizView[]>(this._baseURL + "/GetQuizById/" + id, httpOptions);

  }

  getStudentQuizes(id) {


    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.get<GetStudentQuizes[]>(this._baseURL + "/GetStudentQuizes/" + id, httpOptions);
  }

  getQuizQuestions(id) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.get<GetQQuestions[]>(this._baseURL + "/GetQQuestions/" + id, httpOptions);
  }  //getQuizQuestions Ends here


  quizSubmit(answerSubmit: QuizAnswers[]) {

    //  alert('on service');

    //   alert(answerSubmit[0].id);
    //   alert(answerSubmit[0].answerGiven);
    //   alert(answerSubmit[1].id);
    //   alert(answerSubmit[1].answerGiven);
    //   alert(answerSubmit[2].id);
    //   alert(answerSubmit[2].answerGiven);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.post<string>(this._baseURL + '/AnswerSubmit', answerSubmit);

  }

  updateQuiz(record: UpdateQuiz) {

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<string>(this._baseURL + '/UpdateQuiz/', record);
  }

  logout(): Observable<any> {

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<string>(this._baseURL + '/Logout/', httpOptions);
  }

  totalTimeSpent() {

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get<TimeSpent>(this._baseURL + '/totalTimeSpent/');

  }

  GetStudentAnnouncement(id) {

    return this.http.get<StudentAnnouncement[]>(this._baseURL + "/GetStudentAnnouncement/" + id);

  }

  viewEnrolls(id) {

    return this.http.get<ViewEnrolls[]>(this._baseURL + "/ViewEnrolls/" + id);

  }



  resetSPass(reset: ResetSPassword) {

    //  alert(reset.emailId);
    //  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<string>(this._baseURL + '/resetSPassword', reset);

  }

  getUsers() {

    return this.http.get<UserView[]>(this._baseURL + "/ListUsers");
  }

  assignProgram(assign: any) {

    // alert(assign.pName);
    // alert(assign.catId);

    //  alert(assign.email)
    return this.http.post<string>(this._baseURL + "/AssignProgram", assign);

  }

  getCourseModules(id: number) {

    return this.http.get<number>(this._baseURL + "/GetCourseModulesId/" + id);
  }

  getPaymentSession(id: any) {

    return this.http.get<any>(this._baseURL + "/CreatePaymentSession/" + id);
  }


  enrollCheck(id: any) {

    return this.http.get<boolean>(this._baseURL + "/enrollCheck/" + id);

    // enrollCheck
  }


  downloadCourseFiles(id: any) {

    const headers = new Headers();

    return this.http.get(this._baseURL + '/GetCourseDownload/' + id, { responseType: 'blob' })

  }

  getTeacherUpload(selectUpBatch: number, selectUpAssign: number) {


  }

  postAssignMarks(marks: MarksAssigned) {

    return this.http.post(this._baseURL + "/MarksAssigned", marks);

  }


  fetchAssignData(id: any) {
    return this.http.get<FetchAssignData>(this._baseURL + "/fetchAssigndata/" + id);

  }

  getPreviousCourse() {
    return this.http.get<GetEnrollment[]>(this._baseURL + '/GetPreviousCourses');
  }


  getCourseCertificate() {
    return this.http.get<GetCCertificate[]>(this._baseURL + '/GetPreviousCourses');
  }

  getMyPreviousCourse() {
    return this.http.get<GetEnrollment[]>(this._baseURL + '/GetMyPreviousCourses');
  }


  getSQuestions(id: any) {
    return this.http.get<GetSQuestions[]>(this._baseURL + '/GetSQuestions/' + id);
  }

  replyQuestion(reply: any) {

    return this.http.post<string>(this._baseURL + '/replyQuestion', reply);

  }
  getAlertClick() {
    return this.http.get<GetAlertClick[]>(this._baseURL + '/GetAlertClick');

  }

  getCourseImage(id: any): Observable<Blob> {
    return this.http.get(this._baseURL + '/GetCourseImage/' + id, { responseType: 'blob' });

  }
  getQuizDetail(id: any) {
    return this.http.get<QuizDetail>(this._baseURL + "/GetQuizDetail/" + id);
  }
  getQuizProgress(id: any) {
    return this.http.get<ViewQuizProgress[]>(this._baseURL + "/ViewQuizProgress/" + id);
  }

  getQuizResult(id: any) {

    return this.http.get<ViewQResult>(this._baseURL + "/GetQuizResult/" + id);
  }

  ////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////
  getAProgress(id: any) {
    return this.http.get<ViewAProgress[]>(this._baseURL + "/ViewAProgress/" + id);
  }
  //////////////////////////////////////////////////////////////////////////
  getAssignResult(id: any) {
    return this.http.get<ViewAResult>(this._baseURL + "/GetAssignResult/" + id);
  }

  //////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////
  //////////////////////////////
  //////////////////////////////

  searchCourses(id: any) {

    return this.http.get<ProgramView[]>(this._baseURL + "/SearchECourses/" + id);

  }

  //////////////////////////////////
  ///////////////////////////////////////

  getCourseCount() {
    return this.http.get<CourseCount>(this._baseURL + "/CourseCount/");
  }
  //////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////




  getVideoCourse(id: any) {



    let queryParams = new HttpParams();
    queryParams = queryParams.append("id", id);
    const url = this._baseURL + "/GetAVideoCourse";

    return this.http.get<setVideos[]>(url, { params: queryParams });

    // const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    //  return this.http.get<viewVideo[]>(this._baseURL + "/GetVideoCourse/" + id,httpOptions);

  }
  /////////////////////////////////////////////////////////////////////////////////////////////////
  bgetVideoDuration(videoUrl: string): Promise<number> {
    return new Promise((resolve, reject) => {
      
      const videoId = this.getHtml(videoUrl);

      const options = {
        id: videoId,
        width: 640,
        loop: false
      };

     
    });
  }

  private extractVideoIdxxxx(url: string): string {
    const regex = /https:\/\/vimeo\.com\/(\d+)/;
    const match = regex.exec(url);
    return match ? match[1] : '';
  }


  private extractVideoId(url: string): string | null{
    // Handle different formats of Vimeo URLs
   // const regex = /(?:https:\/\/vimeo\.com\/(?:video\/)?|player\.vimeo\.com\/video\/)(\d+)/;
   // const match = regex.exec(url);
   // return match ? match[1] : null;



   const regex = /player\.vimeo\.com\/video\/(\d+)/;
    const matches = url.match(regex);
   return matches ? matches[1] : null;



  }

  getHtml(message: any): string {

    let val = message;

    let vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;
    let parsed = val.match(vimeoRegex);
    let ssss = parsed[1].trim();
    return ssss
  }


  getVideoDuration(embedUrl: string): Promise<number> {
    return new Promise((resolve, reject) => {
      const videoId = this.extractVideoId(embedUrl);
      //alert(videoId);
      if (!videoId) {
        reject('Invalid Vimeo URL');
        return;
      }



      const iframe = document.createElement('iframe');
      iframe.src = `https://player.vimeo.com/video/${videoId}`;

     
      const player = new Player(iframe);

      player.getDuration().then((duration: number) => {
        
        resolve(duration);
      }).catch((error: any) => {
        reject(error);
      });
    });
  

  }


  
}

