import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from "rxjs";


@Injectable({
  providedIn: "root"
})


export class SetDeSet {
  setQuiz: boolean = false;
  private subject = new Subject<any>();
  

  constructor() {
   // this.setQuiz = false;
  }


  sendMessage(message: boolean) {
    this.subject.next({ boolean: message });
  }

  clearMessage() {
    this.subject.next();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  /*
  setValue(value: boolean) {
    this.setQuiz = value;
  }

  getValue() {
    return this.setQuiz;
  }
  */
}
