import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GetAlertClick } from '../../interfaces/GetAlertClick';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-user-side',
  templateUrl: './user-side.component.html',
  styleUrls: ['./user-side.component.css']
})
export class UserSideComponent implements OnInit {
  getAlertClick: GetAlertClick[];
  tempAlertClick: GetAlertClick;
  tempId: string;
  clickEvent: boolean = false;
  lastClick: any[];
  isFocused: boolean = false;
  buttonStyle: any = {};
  constructor(private authService: AuthService, private uservice: UserService, private _router: Router ) {
   
  }

  ngOnInit() {
  
    //  localStorage.removeItem('lastClick');
        this.getAlert();
  }

  ////////////////////////////////////////////////
  async logout() {
    //  await this.uservice.logout().subscribe(data => {
    //     alert(data);      
    //   })
    ////////////////////////////////////////////////
    localStorage.setItem('quizStatus', 'false');
    ///////////////////////////////////////////////
    await this.uservice.logout().toPromise();
    this.authService.logout();
  }
 
  getAlert() {
    const lastClick = JSON.parse(localStorage.getItem('lastClick'));
    this.uservice.getAlertClick().subscribe(data => {
      this.getAlertClick = data;
     
      for (let i = 0; i < this.getAlertClick.length; i++) {  //How to properly iterate here!!
        if (this.getAlertClick[i].id != Number(lastClick[i].id)) {         
          this.clickEvent = false;
          break;
        }
        else {
          this.clickEvent = true;
        }
      }

    });
   // const getClick = localStorage.setItem('lastClick','');
  }
 
  checkAlert() {

  }
  notifi_alert() {

    if (this.getAlertClick.length > 0) {

     
      localStorage.setItem('lastClick', JSON.stringify(this.getAlertClick));
      this.clickEvent = true;
    }
      
   
    this._router.navigate(['/users/unotifications']);
  

  }
    ///////////////////////////////////////////////


  onMouseEnter() {
    this.buttonStyle = {
      'background-color': 'blue',
      'color': 'white'
      // Add any other styles you want to change on hover
    };
  }

  onMouseLeave() {
    // Reset the styles when the mouse leaves
    this.buttonStyle = {};
  }



  }



