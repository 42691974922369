import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';


@Component({
  selector: 'app-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['./all-users.component.css']
})
export class AllUsersComponent implements OnInit {

    public users: User[];

    constructor(private service: UserService) { }

    ngOnInit() {
        this.service.getAllUsers().subscribe(data => { this.users = data; })
  }

}
