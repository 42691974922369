import { Component, OnInit } from '@angular/core';
import { Login } from './interfaces/Login';
import { UserRole } from './interfaces/UserRole';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { SetDeSet } from './components/set-deset';
import { Subscription } from 'rxjs';

///////////////////////////
/////////////////////////////////
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { fader,slider } from './animations';
import { HostListener } from '@angular/core';
/////////////////////////////////
//////////////////////////////////

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    slider
  ]
})
export class AppComponent implements OnInit{
  @HostListener('window:beforeunload', ['$event'])
  handleClose($event) {


    $event.preventDefault;
  //  if ($event.clientX < 0 && $event.clientY < 0) {
      var conm = "\o/";
      localStorage.setItem('quizStatus', 'false');
   // console.log('is window:unload');
       // $event.returnValue = conm;
      
  //  }
      

  }//handleClose ends here


  @HostListener('window:load',['$event'])
  onLoad($event) {
    var conm = "\o/";
    localStorage.setItem('quizStatus', 'false');
  //  console.log('is window:load');
  }



  title = 'app';
  opened: boolean; 
  //////////////////////////////////////
  //////////////////////////////////////////
  /////////////////////////////////////////////
  mobileQuery: MediaQueryList;

  fillerNav = Array(5).fill(0).map((_, i) => `Nav Item ${i + 1}`);

  fillerContent = Array(2).fill(0).map(() =>
    `Open side nav, and click on any navigation to close the opened side nav.`);

  private _mobileQueryListener: () => void;
  ////////////////////////////////////////////////
  message: boolean;
  subscription: Subscription;

  //////////////////////////////////////////////////
  userDataSubscription: any;
  userData = new Login();
  userRole = UserRole;
  quizStatus: boolean = false;
  //////////////////////////////////////////////////


  constructor(private authService: AuthService, private uservice: UserService, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,private setQ:SetDeSet) {
        if (localStorage.getItem('authToken')) {
          this.authService.setUserDetails();

          //////////////////////////////////
          ///////////////////////////////////
          this.mobileQuery = media.matchMedia('(max-width: 600px)');
          this._mobileQueryListener = () => changeDetectorRef.detectChanges();
          this.mobileQuery.addListener(this._mobileQueryListener);
          /////////////////////////////////////
          /////////////////////////////////////
          

          
    }

    this.userDataSubscription = this.authService.userData.asObservable().subscribe(data => {
      this.userData = data;
    });


   // this.subscription = this.setQ.getMessage().subscribe(message => { this.message = message; });
  }

 

  ngOnInit() {

  //  localStorage.setItem('enrollId','0');
    localStorage.setItem('vdi', '0');
    localStorage.setItem('parsed', 'none');

    localStorage.setItem('tokStatus', 'Yes');
    let quizStatus=localStorage.getItem('quizStatus');
    this.quizStatus = Boolean(JSON.parse(quizStatus));
      window.name = 'lmswindow';
 //   alert(this.quizStatus);
 
}


  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation']
  }

  checkAnnouncement() {
    if (this.userData.isLoggedIn && this.userData.role == this.userRole.User) {

    }
  }


  doBeforeUnload() {
    // Alert the user window is closing 
    alert('welcome');
  }

  doUnload() {
    // Clear session or do something
   // this.auth.getLogout();
    alert('bye');
  }

}
