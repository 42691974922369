import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-manager-side',
  templateUrl: './manager-side.component.html',
  styleUrls: ['./manager-side.component.css']
})
export class ManagerSideComponent implements OnInit {

  constructor(private authService: AuthService, private uservice: UserService) { }

  ngOnInit() {
  }

  ////////////////////////////////////////////////
  async logout() {
    //  await this.uservice.logout().subscribe(data => {
    //     alert(data);      
    //   })
    await this.uservice.logout().toPromise();
    this.authService.logout();
  }
    ///////////////////////////////////////////////

}
